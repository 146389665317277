{
  "profile": "Perfil",
  "skills": "Habilidades",
  "projects": "Proyectos",
  "software": "Software",
  "contact": "Contacto",
  "courses": "Cursos",
  "title": "¡Hola, soy Tristan!",
  "subtitle": "Un desarrollador <span class=\"devtype\">Java</span>, <span class=\"devtype\">Python</span> y <span class=\"devtype\">Web</span>",
  "profileDescription": "Soy Tristan Clowez, un desarrollador informático de 18 años, apasionado y con 4 años de experiencia. Mi experiencia incluye los lenguajes de programación Java y Python, así como tecnologías front-end (HTML, CSS, JavaScript). También uso NodeJS para el desarrollo de back-end. Actualmente estoy en mi primer año de estudios preparatorios en la ESIEA. ¡Ven a descubrir mis proyectos y habilidades en desarrollo!",
  "totalCodeTime": "Horas totales de código desde febrero de 2021:",
  "averageCodeTime": "Horas promedio de código por día desde febrero de 2021:",
  "geoQuizDescription": "GeoQuiz es un sitio web donde puedes poner a prueba tus conocimientos de geografía. Dispone de diferentes modalidades y se están creando otras nuevas. El sitio está en constante evolución y se añaden nuevas funciones con regularidad.",
  "geoQuizLanguages": "Idiomas utilizados: <strong>React JS</strong>, <strong>SCSS</strong>",
  "geoQuizVisitWebsite": "Visite el sitio web",
  "seeProject": "Ver proyecto",
  "javawarsDescription": "Un proyecto Java que simplifica el uso de la API de Guild Wars 2. Guild Wars 2 es un MMORPG lanzado en 2012 y existe una API disponible para desarrolladores. Este proyecto contiene una serie de clases para facilitar el uso de la API, junto con comentarios (Proyecto en desarrollo y no disponible como código abierto por el momento).",
  "javawarsLanguages": "Idiomas utilizados: <strong>Java</strong>",
  "softwareLogo": "Logo",
  "softwareName": "Título",
  "softwareCompany": "Empresa",
  "softwareTimeUse": "Tasa de uso",
  "softwareLink": "Enlace",
  "socialText": "Apasionado por el desarrollo, pongo mi experiencia a su disposición para crear soluciones digitales innovadoras y personalizadas. Mi compromiso con la calidad, la creatividad y la excelencia garantiza resultados que satisfacen sus necesidades y lo ayudan a alcanzar sus objetivos.",
  "inputNamePlaceholder": "Nombre*",
  "inputNameError": "*El nombre es obligatorio",
  "inputEmailPlaceholder": "Email*",
  "inputEmailError": "*El correo electrónico es obligatorio",
  "inputSubjectPlaceholder": "Asunto*",
  "inputSubjectError": "*El asunto es obligatorio",
  "inputSendMessage": "Enviar mensaje",
  "successSendMessage": "El mensaje se ha enviado correctamente",
  "errorSendMessage": "Se ha producido un error al enviar el mensaje"
}