{
  "profile": "Profil",
  "skills": "Compétences",
  "projects": "Projets",
  "software": "Logiciels",
  "contact": "Contact",
  "courses": "Cours",
  "title": "Salut, je suis Tristan !",
  "subtitle": "Un développeur <span class=\"devtype\">Java</span>, <span class=\"devtype\">Python</span> et <span class=\"devtype\">Web</span>",
  "profileDescription": "Je suis Tristan Clowez, un développeur informatique de 18 ans, passionné et avec 4 ans d'expérience. Mon expertise inclut les langages Java, Python et les technologies front-end (HTML, CSS, JavaScript). J'utilise aussi NodeJS pour le back-end. Je suis actuellement en première année de cycle préparatoire à l'ESIEA. Venez découvrir mes projets et compétences en développement !",
  "totalCodeTime": "Nombre d'heures de code depuis Février 2021 :",
  "averageCodeTime": "Nombre d'heures de code par jour en moyenne depuis Février 2021 :",
  "geoQuizDescription": "GéoQuiz est un site web où on peut tester ses connaissances en géographie. Différents modes sont disponbiles et de nouveaux modes sont en cours de création. Le site est en constante évolution et de nouvelles fonctionnalités sont ajoutées régulièrement.",
  "geoQuizLanguages": "Langage utilisé : <strong>React.js</strong>, <strong>SCSS</strong>",
  "geoQuizVisitWebsite": "Visiter le site",
  "seeProject": "Voir le projet",
  "javawarsDescription": "Un projet réalisé en Java permettant une utilisation simplifié de l'API de Guild Wars 2. Guild Wars 2 est un MMORPG sortie en 2012 et une API est disponible pour les développeurs. Ce projet contient de nombreuses classes permenttant une meilleure utilisation de l'API avec des commentaires (Projet en cours de développement et non disponible en open source pour l'instant).",
  "javawarsLanguages": "Langages utilisés : <strong>Java</strong>",
  "softwareLogo": "Logo",
  "softwareName": "Titre",
  "softwareCompany": "Entreprise",
  "softwareTimeUse": "Taux d'utilisation",
  "softwareLink": "Lien",
  "socialText": "Passionné de développement, je mets mon expertise à votre disposition pour créer des solutions digitales innovantes et sur mesure. Mon engagement envers la qualité, la créativité et l'excellence vous garantit des résultats qui répondent à vos besoins et vous aident à atteindre vos objectifs.",
  "inputNamePlaceholder": "Nom*",
  "inputNameError": "*Le nom est obligatoire",
  "inputEmailPlaceholder": "Email*",
  "inputEmailError": "*L'email est obligatoire",
  "inputSubjectPlaceholder": "Sujet*",
  "inputSubjectError": "*Le sujet est obligatoire",
  "inputSendMessage": "Envoyer le message",
  "successSendMessage": "Le message a été envoyé avec succès",
  "errorSendMessage": "Une erreur est survenue lors de l'envoi du message"
}