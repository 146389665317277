{
  "profile": "Profile",
  "skills": "Skills",
  "projects": "Projects",
  "software": "Software",
  "contact": "Contact",
  "courses": "Courses",
  "title": "Hi, I'm Tristan!",
  "subtitle": "A <span class=\"devtype\">Java</span>, <span class=\"devtype\">Python</span> and <span class=\"devtype\">Web</span> developer",
  "profileDescription": "I am Tristan Clowez, a 18-year-old computer developer, passionate and with 4 years of experience. My expertise includes the Java and Python programming languages, as well as front-end technologies (HTML, CSS, JavaScript). I also use NodeJS for back-end development. I'm currently in my first year of preparatory studies at ESIEA. Come discover my projects and development skills!",
  "totalCodeTime": "Total hours of code since February 2021:",
  "averageCodeTime": "Average hours of code per day since February 2021:",
  "geoQuizDescription": "GeoQuiz is a website where you can test your knowledge of geography. Different modes are available, and new ones are currently being created. The site is constantly evolving, and new features are added regularly.",
  "geoQuizLanguages": "Languages used: <strong>React JS</strong>, <strong>SCSS</strong>",
  "geoQuizVisitWebsite": "Visit website",
  "seeProject": "See project",
  "javawarsDescription": "A Java project to simplify use of the Guild Wars 2 API. Guild Wars 2 is an MMORPG released in 2012, and an API is available for developers. This project contains a number of classes to make it easier to use the API, with comments (Project under development and not yet available as open source).",
  "javawarsLanguages": "Languages used: <strong>Java</strong>",
  "softwareLogo": "Logo",
  "softwareName": "Title",
  "softwareCompany": "Company",
  "softwareTimeUse": "Usage rate",
  "softwareLink": "Link",
  "socialText": "Passionate about development, I offer my expertise to create innovative and customized digital solutions for you. My commitment to quality, creativity, and excellence ensures results that meet your needs and help you achieve your goals.",
  "inputNamePlaceholder": "Name*",
  "inputNameError": "*Name is required",
  "inputEmailPlaceholder": "Email*",
  "inputEmailError": "*Email is required",
  "inputSubjectPlaceholder": "Subject",
  "inputSubjectError": "*Subject is required",
  "inputSendMessage": "Send message",
  "successSendMessage": "The message has been sent successfully",
  "errorSendMessage": "An error has occurred while sending the message"
}